import apiClient from '@/plugins/apiClient'
let basePath = "cms/users";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: [],
          single: null,
          errors: null,
          message: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          filters: {
               wheres : []
          }
     },
     getters:{
          list: state => state.list,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
          meta: state => state.meta,
          filters: state => state.filters,
     },
     mutations:{
        error(state, err) {
            state.message = err.response.data.message
            state.errors = err.response.data.errors
        },
        setList(state, resp) {
            state.list = resp.data
            state.single = resp.data[0]
        },
        setMeta(state, resp){
          state.meta = resp.meta
        },
        setSingle(state, resp){
          state.single = resp.data
        },
        setSingleContracts(state, resp){
          state.single.contracts = resp.data
        },
        setSingleStores(state, resp){
          state.single.stores = resp.data
        }
     },
     actions:{
          getList({commit, state}, data){
               return new Promise((resolve, reject) => {
                    var params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'/search?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createUser({commit}, user){
               return new Promise((resolve, reject) => {
                    apiClient.post(basePath+'/create', user).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, user_uid){
               return new Promise((resolve, reject) => {
                    apiClient.get(basePath+'/'+user_uid).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSingle({commit, state}, data){
               return new Promise((resolve, reject) => {
                    apiClient.patch(basePath+'/'+state.single.id+'/update',data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          removeErrors({state}){
               state.errors = null;
               state.message = null;
          },
          setMeta({commit}, data){
               commit('setMeta',{meta:data});
               // commit('success', true);
          }
     },
}