<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card :class="addClass+ ' my-5 '" theme="light">
    <v-card-title>
      <h3 class="text-uppercase">{{title ?? 'DEBUG OBJ'}}
     
        
      </h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-actions class="text-center">
        <v-btn color="info" class="mx-auto" @click="copyContent">
          <v-icon>mdi-content-copy</v-icon> COPY CONTENT
        </v-btn>
        <v-btn color="info" class="mx-auto" @click="pasteContent">
          <v-icon>mdi-content-paste</v-icon> PASTE CONTENT
        </v-btn>
        <v-btn-toggle color="info">
          <v-btn @click="deep ++">
            <v-icon>mdi-eye-plus</v-icon> 
          </v-btn>
          <v-btn  @click="deep --">
            <v-icon>mdi-eye-minus</v-icon> 
          </v-btn>
        </v-btn-toggle>

    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text v-if="newValue">
      <v-sheet
          dark
          class="px-5"
      >       
              <vue-json-pretty
                  v-model:data="newValue"
                  :show-double-quotes="true"
                  :deep="deep"
                  :editable="editable"
                  showLineNumber
                  showLine
                  showLength
                  @change="updateObj"
              >
              </vue-json-pretty>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import copy from 'copy-text-to-clipboard';

export default {
  name: "ObjDebug",
  components: {VueJsonPretty},
  props: {
    addClass: {
      type: String
    },
    editable:{
      type: Boolean,
      default: false,
    },  
    deep:{
      type: Number,
      default: 2,
    },  
    title: {
      type: String
    },
    objs: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    newValue: null,
  }),
  watch:{

  },
  mounted(){
     this.newValue = this.objs
  },
  methods:{
    updateObj(){
      this.$emit("update", this.newValue)
    },
    selectedChanged(newVal, oldVal){
    },
    async copyContent(){
      let json =  JSON.stringify(this.newValue);
      copy(json);
      alert( 'Content Copyed Successfuly!');
    },
    async pasteContent(){

      let copiedtext = "ERROR";

      if (navigator.clipboard && window.isSecureContext) {
        copiedtext = await navigator.clipboard.readText();
      }else{
        copiedtext = prompt('Clipboard API IS NOT available, please paste with "cmd + v"');
      }

      alert( 'Content Paste Successfuly!');

      if(!this.isJsonString(copiedtext)){
        alert( 'This content is not JSON! Please paste a valid Json string');
        return false
      }

      this.newValue = JSON.parse(copiedtext);

      this.updateObj()

    },
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
  }
}
</script>

<style scoped>

</style>