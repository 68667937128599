import apiClient from '@/plugins/apiClientDrive'

export default {
    namespaced: true,
    state:{
        errors: null,
        success: null,
        message: null,
        scopes: [
            "contact-info",
            "medium-term-quotations",
            "short-term-quotations",
            "newsletter",
            "hr-application",
        ],
        actions:{
            "contact-info":'leadsContact',
            "medium-term-quotations":'leadsContact',
            "short-term-quotations":'leadsContact',
            "newsletter":'leadsContact',
            "hr-application":'leadsContact',
            // "hr-application":'hrApplication', in attesa di ok
        }
    },
    getters:{
        success: state => state.success,
        errors: state => state.errors,
        message: state => state.message,
        scopes: state => state.scopes,
        actions: state => state.actions,
    },
    mutations:{
        error(state, err) {
            if(err.response.data.message){
                state.message = err.response.data.message;
                state.errors = err.response.data.errors;
            }else{
                state.message = err.response.data;
                state.errors = err.response.status;
            }
            state.success = false;
        },
        reset(state){
            state.errors = null
            state.message = null
            state.success = null
        },
        setSuccess(state, resp){
            state.errors = null
            state.message = "commons.success-leads"
            state.success = true
        },
    },
    actions:{
        resetErrors({commit}){
            commit('reset')
        },
        leadsContact({commit},data){
            return new Promise((resolve, reject) => {
                if(localStorage.getItem('queryParams')){
                    data.tracking = JSON.parse(localStorage.getItem('queryParams'));
                }
                apiClient.post("/leads", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        leadsContactUpload({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.upload("/leads", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        contactInfo({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("/leads/contacts", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        mediumTermQuotations({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("/leads/medium-term-quotations", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        shortTermQuotations({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("/leads/short-term-quotations", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        newsLetter({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("/leads/newsletters", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        hrApplication({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("/leads/hr-application", data).then(resp => {
                        commit('setSuccess', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
    },
}