import apiClient from '@/plugins/apiClientDrive'
import qs from 'qs';

export default {
     namespaced: true,
     state:{
        errors: null,
        message: null,
        single: null,
        meta: {
            page: 1,
            totalPages: 1,
            totalElements: 0,
        },
        list: [],
        brands: (sessionStorage.getItem('brands')) ? JSON.parse(sessionStorage.getItem('brands')) : [],
        types: (sessionStorage.getItem('types')) ? JSON.parse(sessionStorage.getItem('types')) : [],
        models: [],
        fuels: [],
        powers: [],
        drives: [],
        seats: [],
        language: localStorage.getItem('locale'),
        transmissions: [],
        trunks: [],
        externalColors: [],
     },
     getters:{
        errors: state => state.errors,
        message: state => state.message,
        list: state => state.list,
        meta: state => state.meta,
        single: state => state.single,
        brands: state => state.brands,
        types: state => state.types,
        models: state => {
            return [...state.models].sort((a, b) => {
                const brandA = a.brand?.toLowerCase() || '';
                const brandB = b.brand?.toLowerCase() || '';
                return brandA.localeCompare(brandB);
            });
        },
        fuels: state => state.powers,
        powers: state => state.powers,
        drives: state => state.drives,
        seats: state => state.seats,
        transmissions: state => state.transmissions,
        trunks: state => state.trunks,
        externalColors: state => state.externalColors,
     },
     actions:{
        getBrands({commit}, data){
            var online = data.online ? data.online : false
            var availableOnly = data.availableOnly ? data.availableOnly : false
            
            var params = "?online="+online+"&availableOnly="+availableOnly
            

            return new Promise((resolve, reject) => {
                apiClient.get("cars/brands"+params).then(resp => {
                        commit('setBrands', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getTypes({commit}){

            return new Promise((resolve, reject) => {
                apiClient.get("cars/types").then(resp => {
                        commit('setTypes', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getModels({commit}, data){
            var online = data.online ? data.online : false
            var availableOnly = data.availableOnly ? data.availableOnly : false

            
            var params = "?online="+online+"&availableOnly="+availableOnly


            return new Promise((resolve, reject) => {
                apiClient.get("cars/models"+ params).then(resp => {
                        commit('setModels', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getFuels({commit}){
            return new Promise((resolve, reject) => {
                apiClient.get("cars/powers").then(resp => {
                        commit('setFuels', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getSeats({commit}){
            return new Promise((resolve, reject) => {
                apiClient.get("cars/seats").then(resp => {
                    const seats = resp.data.filter(seat => seat.label !== '6');
                        commit('setSeats', seats);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getExternalColors({commit}){
            return new Promise((resolve, reject) => {
                apiClient.get("cars/external-colors").then(resp => {
                        commit('setExternalColors', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getList({commit}, data){
            return new Promise((resolve, reject) => {
                let params =  qs.stringify(data, { arrayFormat: 'indices' })
                apiClient.get("cars?"+params,).then(resp => {
                        commit('setList', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        searchSingle({state, commit}, car_id){
            return new Promise((resolve, reject) => {
                apiClient.get("search/cars/"+car_id+'?language='+state.language).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        }
     },
     mutations:{
        error(state, err) {
            if(err.response){
                if(err.response.message){
                    state.message = err.response.data.message;
                }else{
                    state.message = "API ERRROR"
                }
                if(err.response.errors){
                    state.errors = err.response.data.errors;
                }else{
                    state.errors = {error: "API CONNECTION"}
                }
            }
           
        },
        setBrands(state, data) {
            state.brands = data;
            sessionStorage.setItem('brands', JSON.stringify(state.brands));
        },
        setTypes(state, data) {
            state.types = data;
            sessionStorage.setItem('types', JSON.stringify(state.types));
        },
        setModels(state, data) {
            state.models = data;
        },
        setFuels(state, data) {
            state.powers = data;
            state.fuels = data;
        },
        setSeats(state, data) {
            state.seats = data;
        },
        setExternalColors(state, data) {
            state.externalColors = data;
        },
        setSingle(state, data) {
            state.single = data.fleet[0];
        },
        setList(state, data) {
            state.list = data.content
            state.meta.page = data.page
            state.meta.totalPages = data.totalPages
            state.meta.totalElements = data.totalElements
        }
    }
}