<!-- eslint-disable vue/no-mutating-props -->
<script setup>

</script>
<template>
     <v-row>
          <v-col cols="12" v-if="title">
               <h5>{{ title }}</h5>
          </v-col>
          <v-col cols="12">
                    <v-table :density="dense">
                    
                         <thead>
                         </thead>
                         <tbody>
                         <tr
                              v-for="(item, key,i) in table"
                              :key="key+i"
                         >
                              <template v-if="isString(item) || isNumber(item) || item == null">
                                   <th>{{ key }}</th>
                                   <template v-if="isDate(key)">
                                        <td v-if="item">{{ $moment(item).locale(locale).format('DD/MM/YYYY') }} </td>
                                        <td v-else>-</td>
                                   </template>
                                   <template v-else-if="isDateTime(key)">
                                        <td v-if="item">{{ $moment(item).locale(locale).format('DD/MM/YYYY HH:mm') }} </td>
                                        <td v-else>-</td>
                                   </template>
                                   <template v-else>
                                        <td v-if="isLink(item)">
                                             <a :href="item" target="_blank" rel="noopener noreferrer">{{item}}</a>
                                        </td>
                                        <td v-else>{{ item ?? '-' }}</td>
                                   </template>
                              </template>

                              <template v-else-if="isBoolean(item) || item == null">
                              <th>{{ key }}</th>
                              <td>
                                   <v-icon color="green accent-4" v-if="item">mdi-check-circle</v-icon>
                                   <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
                              </td>
                              </template>
                              <template v-else-if="isSimpleArray(item)">
                                   <th>{{ key }}</th>
                                   <td> 
                                        <v-chip class="ma-2" outlined pill size="small" v-for="val in item" :key="val">{{val}}</v-chip>
                                   </td>
                              </template>
                              <template v-else-if="isArrayOfObjects(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels inset elevation="0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-title>
                                             <b>{{ key }}</b>
                                             </v-expansion-panel-title>
                                             <v-expansion-panel-text v-for="(obj,n) in item" :key="n">
                                                       <template v-if="isString(obj)">
                                                            <v-chip>{{ obj }}</v-chip>
                                                       </template>
                                                       <template v-if="isObject(obj)">
                                                            <simple-table :table="obj" ></simple-table>
                                                       </template>
                                                       <!-- <simple-table :table="createObj({'tokey':obj},'tokey',key)" ></simple-table> -->
                                                       
                                             </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                              <template v-else-if="isObject(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels variant="accordion" class="elevation-0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-title>
                                             <b>{{ key }}</b>
                                             </v-expansion-panel-title>
                                             <v-expansion-panel-text>
                                                  <simple-table :table="item" ></simple-table>
                                             </v-expansion-panel-text>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                         </tr>
                         </tbody>
                   
                    </v-table>
              
          </v-col>
     </v-row>
</template>

<script>
import moment from 'moment'

  export default {
     name:"SimpleTable",
     props:{
          table: {
               type: [Object, Array],
               required: true,
          },
          dense:{
               type: String,
               required: false,
               default: 'default',
          },
          title:{
               type: String,
               required: false,
               default: null,
          },
     },
     data:()=>({
          datesKey: ['date_of_birth','start','end'],
          datesTimeKey: ['created_at','paid_at','updated_at','email_verified_at','phone_verified_at','issued_on','confirmed_at','visible_at','posted_at','created_at','signed_at','filled_at','paid_at'],
          datesImages: ['thumbnail','avatar'],
          locale: sessionStorage.getItem("locale"),
     }),
     mounted(){
     },
     methods:{
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isSimpleArray(val){
               if(this.isArray(val)){
                    if(!this.isArray(val[0]) && !this.isObject(val[0])){
                         return true;
                    }
               }
               return false
          },
          isArrayOfObjects(val){
               if(this.isArray(val)){
                    if(this.isObject(val[0])){
                         return true;
                    }
               }
               return false
          },
          isBoolean(val){
               return typeof val === 'boolean' || val instanceof Boolean
          },
          isNumber(val){
               return typeof val === 'number' || val instanceof Number
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
          isImage(key){
               return this.datesImages.includes(key)
          },
          formatDate(date, format='DD/MM/YYYY HH:mm'){
                    return moment(date).locale(this.locale).format(format)
          },
          isLink(text){
               if(!text) return false;
               text = text.toString();
               return (text.startsWith("https://") || text.startsWith("http://") || text.startsWith("www."));
          },
          createObj(object, key, newKey)  {

               const clonedObj = Object.assign({}, object);
               const targetKey = clonedObj[key];

               delete clonedObj[key];

               clonedObj[newKey] = targetKey;

               return clonedObj;

          }
     }
  }
</script>