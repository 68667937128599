import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
        authenticated: (localStorage.getItem('cmsauthenticated')=="true"),
        token: localStorage.getItem('access_token'),
        isAdmin: (localStorage.getItem('isAdmin')=="true"),
        isEditor: (localStorage.getItem('isEditor')=="true"),
        isUser: (localStorage.getItem('isUser')=="true"),
        errors: {},
        message: null,
        success: null,
     },
     getters:{
        isAuthenticated: state => state.authenticated,
        expire_at: state => state.expire_at,
        errors: state => state.errors,
        message: state => state.message,
        isAdmin : state => state.isAdmin,
        isEditor : state => state.isEditor,
        isUser : state => state.isUser,
        success: state => state.success,
     },
     actions:{
        login({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("auth/login", data).then(resp => {
                        commit('success', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        logout({commit},data){
            return new Promise((resolve, reject) => {
                apiClient.post("auth/logout", data).then(resp => {
                        commit('logout', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        commit('logout');
                        reject(err);
                    });
            })
        },
        forgotPassword({commit}, data){
            return new Promise((resolve, reject) => {
                apiClient.post("auth/forgot-password", data).then(resp => {
                        commit('forgotPassword', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        commit('logout')
                        reject(err);
                    });
            })
        },
        resetPassword({commit}, data){
            return new Promise((resolve, reject) => {
                apiClient.post("auth/forgot-password/reset", data).then(resp => {
                        commit('resetPassword', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        commit('logout')
                        reject(err);
                    });
            })
        },
        forceLogout({commit}) {
            commit('logout')
        },
     },
     mutations:{
        error(state, err) {
            if(err.response.status === 422){
                state.errors = err.response.data.errors;
            }
            state.message = err.response.data.message;
        },
        success(state,resp) {

            localStorage.setItem('access_token', resp.access_token)
            localStorage.setItem('locale', resp.language)
            localStorage.setItem('timezone', resp.timezone)
            
            state.authenticated = true;
            localStorage.setItem('cmsauthenticated', true)

            localStorage.setItem('isAdmin', false)
           
            localStorage.setItem('isEditor', false)
            localStorage.setItem('isUser', false)


            if(resp.role == 'admin'){
                localStorage.setItem('isAdmin', true)
                localStorage.setItem('isEditor', true)
                localStorage.setItem('isUser', true)
                state.isUser = true
                state.isAdmin = true
                state.isEditor = true
            }
            if(resp.role == 'editor'){
                localStorage.setItem('isEditor', true)
                localStorage.setItem('isUser', true)
                state.isUser = true
                state.isEditor = true
            }
            if(resp.role == 'user'){
                localStorage.setItem('isUser', true)
                state.isUser = true
            }




            state.authenticated = true
        },
        logout(state) {
            localStorage.clear()
            state.authenticated = false;
            state.token = '';
        },
        forgotPassword(state, resp){
            state.success = true;
            state.message = resp.message
        },
        resetPassword(state, resp){
            state.success = true;
            state.message = resp.message
        }
     }
}