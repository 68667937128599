import apiClient from '@/plugins/apiClientMaps'
const apikey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;


export default {
    namespaced: true,
    state:{
        predictions: [],
        errors: null,
    },
    getters:{
        errors:  state => state.errors,
        predictions: state => state.predictions,
    },
    mutations:{
        setErrors(state, resp){
            state.errors = resp
        },
        setPredictions(state, resp) {
            state.predictions = resp.predictions
        }
    },
    actions:{
        getPredictions({commit}, text){
            return new Promise((resolve, reject) => {

                let url = "/place/autocomplete/json?&key="+apikey+"&input=" +text

                apiClient.get(url).then(resp => {
                    commit('setPredictions', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    commit('setErrors', err);
                    reject(err);
                });
            })
        },
    }
}