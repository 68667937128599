export default [
    {        
        path: '',
        name: 'booking.step-0',
        redirect: 'search',
    },
    {        
        path: 'step-1',
        name: 'booking.step-1',
        component: () => import(/* webpackChunkName: "BookingProcess" */ '@/views/Pages/Booking/FirstStep.vue')
    },
    {        
        path: 'step-2',
        name: 'booking.step-2',
        component: () => import(/* webpackChunkName: "BookingProcess" */ '@/views/Pages/Booking/SecondStep.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {        
        path: 'orderid/:orderId',
        params: 'orderId',
        name: 'booking.oderid',
        component: () => import(/* webpackChunkName: "BookingProcess" */ '@/views/Pages/Booking/OrderId.vue'),
        meta: {
            driveAuth: true,
        },
    },
    {        
        path: 'payment-success',
        name: 'booking.payment-success',
        component: () => import(/* webpackChunkName: "BookingProcess" */ '@/views/Pages/Booking/PaymentSuccess.vue'),
       
    },
    {        
        path: 'payment-failed',
        name: 'booking.payment-failed',
        component: () => import(/* webpackChunkName: "BookingProcess" */ '@/views/Pages/Booking/PaymentFailed.vue'),
       
    },
];