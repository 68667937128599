import apiClient from '@/plugins/apiClient'
let basePath = "/cms/assets";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: [],
          single: null,
          errors: {},
          message: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          filters: {
               wheres : []
          }
     },
     getters:{
          list: state => state.list,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
          meta: state => state.meta,
          filters: state => state.filters,
     },
     mutations:{
          error(state, err) {
               state.errors = err.response.data
          },
          setMeta(state, resp){
               state.meta = resp.meta
          },
          setList(state, resp) {
               state.list = resp.data
               state.single = resp.data[0]
               state.exportfile = null;
          },
          setSingle(state, resp){
               state.single = resp.data
          },
          setFilters(state, resp){
               state.filters = resp.filters
          },
         
     },
     actions:{
          setAsSingle({state}, data){
               state.single = data;
          },
          setMeta({commit}, data){
               commit('setMeta',{meta:data});
          },
          setFilters({commit}, data){
               commit('setFilters',{filters:data});
          },
          getList({commit, state}, data){
               return new Promise((resolve, reject) => {
                    var params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, item){
               return new Promise((resolve, reject) => {
                    apiClient.get(basePath+'/'+item.id).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          upload({commit},formData){
               return new Promise((resolve, reject) => {
                    apiClient.upload(basePath+"/upload", formData).then(resp => {
                         resolve(resp);
                         commit('setSingle', resp.data);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSingle({commit},asset){
               return new Promise((resolve, reject) => {
                    apiClient.patch(basePath+"/"+asset.id+"/update", asset).then(resp => {
                         resolve(resp);
                         commit('setSingle', resp.data);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteSingle({commit},asset){
               return new Promise((resolve, reject) => {
                    apiClient.delete(basePath+"/"+asset.id+"/delete").then(resp => {
                         resolve(resp);
                         commit('setSingle', resp.data);
                     })
                     .catch(err => {
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     }
}