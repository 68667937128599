import apiClient from '@/plugins/apiClientDrive'
import moment from 'moment';
import qs from 'qs';

export default {
    namespaced: true,
    state:{
        success: null,
        errors: null,
        message: null,
        list: [],
        details: null,
        quote: null,
        selected: null,
        orders: [
            'total','popularity','ranking','car.brand_label'
        ],
        orderBy: null,
        search: (localStorage.getItem('search')) ? JSON.parse(localStorage.getItem('search')) :{
            language: localStorage.getItem('locale'),
            carId: null,
            start:{
                loc: null,
                date: null,
                cou: null,
                lat: null,
                lon: null,
                description: null
            },
            end:{
                loc: null,
                date: null,
                cou: null,
                lat: null,
                lon: null,
                description: null
            },
            filter:{
                type: [],
                brand: [],
                model: [],
                fuel: [],
                gear: [],
                seats: [],
                drive: [],
                color: [],
            }
        },
    },
    getters:{
        success: state => state.success,
        errors: state => state.errors,
        orders: state => state.orders,
        orderBy: state => state.orderBy,
        message: state => state.message,
        selected: state => state.selected,
        quote: state => state.quote,
        list: state => state.list,
        search: state => state.search,
        details: state => state.details,
    },
    mutations:{
        error(state, err) {
            state.message = "search-failed";
            state.errors = "errors";
            state.list = [];
            if(err.response){
                if(err.response.data){
                    state.message = "search-failed";
                    state.errors = err.response.data.error.split(",");
                }
            }
            state.success = false;
        },
        success(state, data){
            state.list = data.fleet;
            state.details = data.details;
            state.message = null;
            state.errors = null;
            state.success = true;
        },
        message(state, data) {
            state.message = data;
        },
        reset(state){
            localStorage.removeItem('search')
            state.list = []
            state.selected = null
            state.details = null
            state.errors = null
            state.message = null
            state.success = null
        },
        checkRecents(state){
            if(state.search.start.date){
                let starter = moment(state.search.start.date);
                if(starter < moment().add(4, 'hours')){
                    localStorage.removeItem('details')
                    localStorage.removeItem('search')
                    localStorage.removeItem('selected')
                    state.list = []
                    state.single = null
                    state.details = null
                    state.errors = null
                    state.message = null
                    state.success = null
                }
            }
            
        },
        availability(state, data){
            state.success = true;
            state.message = "available";
        },
        ErrorAvailability(state, data){
            state.success = false;
            state.message = "not-available";
        },
        setSearch(state, data) {
            localStorage.setItem('search', JSON.stringify(data) );
            state.search = data;
        },
        setSingle(state, data) {
            state.selected = data;
        },
        setQuote(state, data) {
            state.quote = data;
        },
        setOrderBy(state, byKey){
            state.orderBy = byKey;
            // eslint-disable-next-line vue/no-mutating-props
            state.list.sort(function(a,b){
                // here a , b is whole object, you can access its property
                // it will return the difference to sort function and then 
                // sort compare that difference is equal to 0 or smaller than 0 or 
                // greater than 0. on the basis of that it will give sorted number list

                if(byKey.includes('.')){
                    var keys = byKey.split('.');
                    if(typeof(a[keys[0]][keys[1]]) == 'string'){
                      return  a[keys[0]][keys[1]].localeCompare(b[keys[0]][keys[1]])
                    }
                    return a[keys[0]][keys[1]] - b[keys[0]][keys[1]];
                }else{
                    return a[byKey] - b[byKey];
                }

            })

            // this.newlist = this.suddividiArray(this.list, this.columns);
            // this.newlist[this.newlist.length-1].splice(0, 0, this.sort);
        },
        successSingle(state, data) {
            state.list = data.fleet;
            state.details = data.details;
            state.message = null;
            state.errors = null;
            state.success = true;

            if(state.list.length > 0) {
                state.selected = state.list[0].car
                state.quote = state.list[0].quote;
            }else{
                state.message = "not-found";
                state.errors = "not-avaiblae";
                state.success = false;
            }

            

        }
    },
    actions:{
        resetErrors({commit}){
            commit('reset')
        },
        setSearch({commit}, data){
            commit('setSearch', data);
        },
        setSingle({commit}, data){
            commit('setSingle', data);
        },
        setQuote({commit}, data){
            commit('setQuote', data);
        },
        setOrderBy({commit}, data){
            commit('setOrderBy', data);
        },
        search({commit}, data){
            commit('setSearch', data);
            return new Promise((resolve, reject) => {
                apiClient.post("search", data).then(resp => {
                        commit('success', resp.data);
                        commit('setOrderBy', 'total');
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        searchSelected({commit}, data){
            commit('setSearch', data);
            return new Promise((resolve, reject) => {
                apiClient.post("search", data).then(resp => {
                        commit('successSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        checkRecents({commit}){
            commit('checkRecents')
        },
        checkAvailability({commit}, data){
            return new Promise((resolve, reject) => {
                let params =  qs.stringify(data, { arrayFormat: 'indices' })
                apiClient.get("cars/"+data.id+'/availability?'+params,).then(resp => {
                        commit('availability', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('ErrorAvailability', err);
                        reject(err);
                    });
            })
        }
    },
}